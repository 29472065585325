import { createTheme } from "@mui/material";

export async function matchHeaders(userRole) {

let appTenant;
let authTenant;
let apikey;

if (userRole === 'ADMIN'){
 appTenant = 'ADMIN';
 authTenant = 'ADMIN';
 apikey = '4QiEYU!4N5NVEbiMszmKiaHaGKogJ!U&Nvmtz&XuBPhq%xPTs&kBncEB7AdaA3HD';

} else if  (userRole === 'ROUNDA'){
    appTenant = 'ROUNDA-STAGE';
    authTenant = 'ROUNDA_PROD';
    apikey = 'F3tDuot6*otiwCp$aRW9ahcPKHkMUwxUUE&tW4#vg84%YV7Ud56s@dVhCzoZrkBpRjwF!96Pp7Z*PJZFZuSC5ZesHScYNtQxij%A^Ta$SNA@QFd*!HZ2k4h@JH3cZYtf';
   
} else if (userRole === 'CENTS2BILLS'){
    appTenant = 'CENTS2BILLS-PROD';
    authTenant = 'CENTS2BILLS_PROD';
    apikey = 'P6!BYQTX%tSB*Qg3v@JsQfetSZWXa&7H%^DxBY*Kx5aXYZmr%oY85SstTQpzodgkhR*D8TthjSA#P%Nk&8NAvVSsJA3*A8@J6fhRs*tCjCjT#7WDjoYtC&W2xEGqTarM';
   
} else if (userRole === 'BYS'){
  appTenant = 'BOOST-YOUR-SUPER-PROD';
  authTenant = 'BOOST_YOUR_SUPER_PROD';
  apikey = 'EuTv%zuKjcLw9X%5RfpyC3BsED6!#LJB2fY4fij9tXWoC!hCkQeGM8ZqZzXaEu$G';
 
} else if (userRole === 'DWINDL'){
  appTenant = 'DWINDL-PROD';
  authTenant = 'DWINDL_PROD';
  apikey = 'dJ6rK$Ck5dcWD!G4#rdFBq$$%Ysv3jt#';
 
} 
   

return {
    appTenant,
    authTenant,
    apikey
}

}


export const roles = {
    admin: 'ADMIN'
}

 

  export const mainTheme = createTheme({
    palette: {
        primary: {
          main: '#ffffff',
        },
        background: {
          default: '#ffffff', // Set your desired background colour here
        },
        card: {
            main: '#000000',
            background: '#d9e6f4',
            color: '#4dabf5'
        },
        action: {
            good: '#4dabf5', // Light blue for "good" actions
            delete: '#f44336', // Red for "delete" actions
            add: '#56b04f', // Red for "delete" actions
          },
  }})

  export const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 2
    }).format(amount);
  };