import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Grid2, Card, Modal, CardContent, Box, Container, Typography, useMediaQuery, CircularProgress, TextField, ThemeProvider, CssBaseline, Button  } from '@mui/material';
import MainNav from '../../components/navigation/MainNav';
import { api } from '../../services/apis';
import { mainTheme, formatCurrency, matchHeaders } from '../../services/settings';
import { useTheme } from '@mui/material/styles';
import NRMAFloatTable from '../../components/floats/NRMAFloatTable';
import PokitPalFloatTable from '../../components/floats/PokitPalFloatTable.';
import EmploymentHeroFloatTable from '../../components/floats/EmploymentHeroFloatTable';


const FloatsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userRole = localStorage.getItem('role');
  const [floatALoading, setFloatALoading] = useState(true);
  const [floatATopUpLoading, setFloatATopUpLoading] = useState(true);
  const [floatBLoading, setFloatBLoading] = useState(true);
  const [floatBTopUpLoading, setFloatBTopUpLoading] = useState(true);
  const [floatCLoading, setFloatCLoading] = useState(true);
  const [nRMATopUpValue, setNRMATopUpValue] = useState(0);
  const [pokitPalTopUpValue, setPokitPalTopUpValue] = useState(0);
  const [openPPTopUp, setOpenPPTopUp] = React.useState(false);
  const [ppAmount, setPPAmount] = useState(0);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);


  const navigate = useNavigate(); // Use navigate for navigation

  useEffect(() => {
    checkRole();
  }, []);

  const checkRole = async () => {
    const isAdmin = localStorage.getItem('role');
    if (isAdmin !== 'ADMIN') {
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingScreen(true); // Set loading state before requests

      try {
        // Wait for all fetch functions to complete using Promise.all
        await Promise.all([
        ]);
      } catch (error) {
        console.error('Error fetching data:', error); // Handle errors if any request fails
      } finally {
        setIsLoadingScreen(false); // Set loading state to false after requests are completed
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, []);

  const defaultShadow = '0 2px 14px 0 rgb(32 40 45 / 8%)';

  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <MainNav />
        <Box
          sx={{
            padding: '20px',
            marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
            marginTop: '30px',
            transition: 'margin-left 0.3s', // Smooth transition when screen size changes
          }}
        >
          {isLoadingScreen ? (
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ marginTop: '100px' }}>
              <CircularProgress size={60} color="secondary" />
            </Box>
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}>
                <Typography variant="h4" sx={{ flexGrow: 1 }}>
                  Floats
                </Typography>
              </Box>

              <Grid2 container spacing={3} fullWidth>
                <Grid2 item xs={12} sm={6} md={4}>
                  <NRMAFloatTable />
                </Grid2>
                <Grid2 item xs={12} sm={6} md={4}>
                  <EmploymentHeroFloatTable />
                </Grid2>
                <Grid2 item xs={12} sm={6} md={4}>
                  <PokitPalFloatTable />
                </Grid2>

                   </Grid2>
            </>
          )}
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default FloatsPage;
