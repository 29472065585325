export async function findRole(userId){
    const users = {
        k6IE2xKIgoZyLUHIfIpoLvzEKl33: 'ROUNDA',
        dB0l2Xe0HdbXt02FUwe8cOqhKx12: 'ROUNDA',
        TkhkeGlLaGgJf2qTWvsCoQ3ZHz43: 'ROUNDA',
        yaeNhxNUt4Xmhxa46YAJuJ9yFa13: 'ADMIN',
        ovEOLIUnjzUWzMZoDjiAoU4RtpJ3: 'ADMIN',
        fafT1VCp0pagx6nQBmFwsfSvmHC3: 'CENTS2BILLS',
        MNHUEjd6B6Z9X55dbDqg3IXzxSF3: 'BYS',
        "1cqiHyNM1peFMi2ipPomDmGPpSL2": 'ADMIN',
        TUZTgdUxYPe8REBMlOasIBSt6Rp2: 'DWINDL',
    } 

    return users[userId] || 'Unknown';
};
