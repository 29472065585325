import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableSortLabel, TableHead, TableRow, Paper, TextField, Button, Box, Container, Select, MenuItem, InputLabel, FormControl, Typography, useMediaQuery, CssBaseline, ThemeProvider, Pagination, Skeleton } from '@mui/material';
import MainNav from '../components/navigation/MainNav';
import { api } from '../services/apis';
import { matchHeaders, mainTheme, formatCurrency } from '../services/settings';
import * as XLSX from 'xlsx';



const Users = () => {
    const [isLoadingScreen, setIsLoadingScreen] = useState(false);
    const userRole = localStorage.getItem('role');
    const theme = mainTheme;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'firstName', direction: 'asc' });
    const [selectedTenant, setSelectedTenant] = useState('');
    const [selectedConsent, setSelectedConsent] = useState('');
    const [distinctTenants, setDistinctTenants] = useState([]);
    const [distinctConsents, setDistinctConsents] = useState([]);
    const [selectedPaused, setSelectedPaused] = useState('');
    const [distinctPaused, setDistinctPaused] = useState([]);
    const [selectedOnboarded, setSelectedOnboarded] = useState('');
    const [distinctOnboarded, setDistinctOnboarded] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const limit = 100;

    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers(page);
    }, [page, searchQuery, selectedTenant, selectedConsent, selectedPaused, selectedOnboarded, sortConfig]);

    useEffect(() => {
        setDistinctTenants([...new Set(users.map(user => user.tenant))]);
        setDistinctOnboarded([...new Set(users.map(user => mapOnboarded(user.onboardingComplete)))]);
        setDistinctConsents([...new Set(users.map(user => mapConsent(user.isConsent)))]);
        setDistinctPaused([...new Set(users.map(user => mapPaused(user.isUserPausedRoundUp)))]);
    }, [users]);

    const mapConsent = (value) => (value === true ? '✔' : '✘');
    const mapPaused = (value) => (value === true ? '✔' : '✘');
    const mapOnboarded = (value) => (value === true ? '✔' : '✘');

    const requestSort = (key) => {
        setSortConfig(prev => ({
            key,
            direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const handleRowClick = (userId) => {
        navigate(`/users/${userId}`);
    };

    const fetchUsers = async (pageNum) => {
        try {
            setIsLoadingScreen(true);
            const headers = await matchHeaders(userRole);
            const response = await axios.get(api.retrieveUsers, {
                params: {
                    page: pageNum,
                    limit,
                    search: searchQuery || undefined,
                    tenant: selectedTenant || undefined,
                    consent: selectedConsent === '✔' ? true : selectedConsent === '✘' ? false : undefined,
                    paused: selectedPaused === '✔' ? true : selectedPaused === '✘' ? false : undefined,
                    onboarded: selectedOnboarded === '✔' ? true : selectedOnboarded === '✘' ? false : undefined,
                    sortKey: sortConfig.key,
                    sortDirection: sortConfig.direction
                },
                headers: {
                    'Content-Type': 'application/json',
                    apikey: headers.apikey,
                    tenant: headers.appTenant,
                    tenantA: headers.authTenant,
                }
            });

            setUsers(response.data.users);
            setTotalPages(response.data.totalPages);
            setTotalUsers(response.data.total);
            setPage(pageNum);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoadingScreen(false);
        }
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(users); // Use raw users since sorting is server-side
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'UsersData.xlsx');
    };

    return (
        <Container maxWidth="xxl">
            <ThemeProvider theme={mainTheme}>
                <CssBaseline />
                <MainNav />
                <Box sx={{ padding: '20px', marginLeft: isMobile ? '0' : '300px', marginTop: '30px', transition: 'margin-left 0.3s' }}>
                    {isLoadingScreen ? (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '20px', mt: '40px' }}>
                                <Skeleton variant="text" width="200px" height="40px" />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '20px' }}>
                                <Skeleton variant="rectangular" width="100%" height="56px" sx={{ mr: '10px' }} />
                                <Skeleton variant="rectangular" width="200px" height="56px" />
                                <Skeleton variant="rectangular" width="200px" height="56px" />
                                <Skeleton variant="rectangular" width="200px" height="56px" />
                                <Skeleton variant="rectangular" width="200px" height="56px" />
                            </Box>
                            <Box align="right" sx={{ mb: 2 }}>
                                <Skeleton variant="rectangular" width="150px" height="40px" />
                            </Box>
                            <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto', overflowX: 'auto', whiteSpace: 'nowrap', borderRadius: 4, boxShadow: 2 }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>ID</TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Consent</TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Onboarded</TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Paused</TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Created Date</TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Tenant</TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Email</TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>First Name</TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Last Name</TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Max</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.from(new Array(8)).map((_, index) => (
                                            <TableRow key={index}>
                                                {Array.from(new Array(10)).map((_, cellIndex) => (
                                                    <TableCell key={cellIndex}>
                                                        <Skeleton variant="text" width="100%" height="30px" />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '20px', mt: '40px' }}>
                                <Typography variant="h4">
                                    Users (Total: {totalUsers})
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: '20px', flexWrap: 'wrap' }}>
                                <TextField
                                    label="Search Users"
                                    variant="outlined"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    sx={{ flexGrow: 1, minWidth: 200 }}
                                />
                                {userRole === 'ADMIN' && (
                                    <FormControl sx={{ minWidth: 200 }}>
                                        <InputLabel>Filter by Tenant</InputLabel>
                                        <Select value={selectedTenant} onChange={(e) => setSelectedTenant(e.target.value)} label="Filter by Tenant">
                                            <MenuItem value="">All Tenants</MenuItem>
                                            {distinctTenants.map((tenant, index) => (
                                                <MenuItem key={index} value={tenant}>{tenant}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                                <FormControl sx={{ minWidth: 200 }}>
                                    <InputLabel>Filter by Consent</InputLabel>
                                    <Select value={selectedConsent} onChange={(e) => setSelectedConsent(e.target.value)} label="Filter by Consent">
                                        <MenuItem value="">All Status</MenuItem>
                                        {distinctConsents.map((consent, index) => (
                                            <MenuItem key={index} value={consent}>{consent}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ minWidth: 200 }}>
                                    <InputLabel>Filter by Onboarded</InputLabel>
                                    <Select value={selectedOnboarded} onChange={(e) => setSelectedOnboarded(e.target.value)} label="Filter by Onboarded">
                                        <MenuItem value="">All Status</MenuItem>
                                        {distinctOnboarded.map((onboarded, index) => (
                                            <MenuItem key={index} value={onboarded}>{onboarded}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ minWidth: 200 }}>
                                    <InputLabel>Filter by Paused</InputLabel>
                                    <Select value={selectedPaused} onChange={(e) => setSelectedPaused(e.target.value)} label="Filter by Paused">
                                        <MenuItem value="">All Status</MenuItem>
                                        {distinctPaused.map((paused, index) => (
                                            <MenuItem key={index} value={paused}>{paused}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box align="right" sx={{ mb: 2 }}>
                                <Button variant="outlined" onClick={exportToExcel} sx={{ backgroundColor: theme.palette.action.good }}>
                                    Export to Excel
                                </Button>
                            </Box>

                            <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 220px)', overflowY: 'auto', overflowX: 'auto', borderRadius: 4, boxShadow: 2 }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>ID</TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === 'isConsent'}
                                                    direction={sortConfig.key === 'isConsent' ? sortConfig.direction : 'asc'}
                                                    onClick={() => requestSort('isConsent')}
                                                >
                                                    Consent
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === 'onboardingComplete'}
                                                    direction={sortConfig.key === 'onboardingComplete' ? sortConfig.direction : 'asc'}
                                                    onClick={() => requestSort('onboardingComplete')}
                                                >
                                                    Onboarded
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === 'isUserPausedRoundUp'}
                                                    direction={sortConfig.key === 'isUserPausedRoundUp' ? sortConfig.direction : 'asc'}
                                                    onClick={() => requestSort('isUserPausedRoundUp')}
                                                >
                                                    Paused
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === '_createdDate'}
                                                    direction={sortConfig.key === '_createdDate' ? sortConfig.direction : 'asc'}
                                                    onClick={() => requestSort('_createdDate')}
                                                >
                                                    Created Date
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === 'tenant'}
                                                    direction={sortConfig.key === 'tenant' ? sortConfig.direction : 'asc'}
                                                    onClick={() => requestSort('tenant')}
                                                >
                                                    Tenant
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === 'email'}
                                                    direction={sortConfig.key === 'email' ? sortConfig.direction : 'asc'}
                                                    onClick={() => requestSort('email')}
                                                >
                                                    Email
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === 'firstName'}
                                                    direction={sortConfig.key === 'firstName' ? sortConfig.direction : 'asc'}
                                                    onClick={() => requestSort('firstName')}
                                                >
                                                    First Name
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === 'lastName'}
                                                    direction={sortConfig.key === 'lastName' ? sortConfig.direction : 'asc'}
                                                    onClick={() => requestSort('lastName')}
                                                >
                                                    Last Name
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                                                <TableSortLabel
                                                    active={sortConfig.key === 'weeklyMaximumLimit'}
                                                    direction={sortConfig.key === 'weeklyMaximumLimit' ? sortConfig.direction : 'asc'}
                                                    onClick={() => requestSort('weeklyMaximumLimit')}
                                                >
                                                    Max
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={10} align="center">
                                                    <Typography>No users found.</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            users.map((user) => (
                                                <TableRow key={user._id} onClick={() => handleRowClick(user._id)} sx={{ cursor: 'pointer' }}>
                                                    <TableCell sx={{ fontSize: 12 }}>{user._id}</TableCell>
                                                    <TableCell sx={{ fontSize: 12 }}>{user.isConsent ? '✔' : ''}</TableCell>
                                                    <TableCell sx={{ fontSize: 12 }}>{user.onboardingComplete ? '✔' : ''}</TableCell>
                                                    <TableCell sx={{ fontSize: 12 }}>{user.isUserPausedRoundUp ? '✔' : ''}</TableCell>
                                                    <TableCell sx={{ fontSize: 12 }}>{new Date(user._createdDate).toLocaleDateString()}</TableCell>
                                                    <TableCell sx={{ fontSize: 12 }}>{user.tenant}</TableCell>
                                                    <TableCell sx={{ fontSize: 12 }}>{user.email}</TableCell>
                                                    <TableCell sx={{ fontSize: 12 }}>{user.firstName}</TableCell>
                                                    <TableCell sx={{ fontSize: 12 }}>{user.lastName}</TableCell>
                                                    <TableCell sx={{ fontSize: 12 }}>
                                                        {user.weeklyMaximumLimit != null && user.weeklyMaximumLimit !== ''
                                                            ? formatCurrency(user.weeklyMaximumLimit / 100)
                                                            : ''}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={(event, value) => setPage(value)}
                                    color="primary"
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </ThemeProvider>
        </Container>
    );
};

export default Users;