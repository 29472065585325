import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CircularProgress, Modal, TableRow, TableCell, TableHead, TableContainer, Paper, TableBody, Table, CardContent, Box, Container, Typography, useMediaQuery, TextField, ThemeProvider, CssBaseline, Button } from '@mui/material';
import MainNav from '../navigation/MainNav';
import { api } from '../../services/apis';
import { mainTheme, formatCurrency, matchHeaders } from '../../services/settings';
import { useTheme } from '@mui/material/styles';

const EmploymentHeroFloatTable = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const userRole = localStorage.getItem('role');
    const [floatALoading, setFloatALoading] = useState(true);
    const [floatATopUpLoading, setFloatATopUpLoading] = useState(true);
    const [employmentHeroTopUpValue, setEmploymentHeroTopUpValue] = useState(0);
    const [employmentHeroFloatSummary, setEmploymentHeroFloatSummary] = useState({
        totalPayouts: 0,
        numberOfPayouts: 0,
        numberOfDays: 0,
        pendingPayouts: 0,
        availableBalance: 0,
        averageAmountPerDay: 0,
        daysLeft: 0,
        numberOfPendingPayouts: 0,
        failedPayouts: 0, // Optional, not in response
        numberOfFailedPayouts: 0 // Optional, not in response
    });
    const [openEHTopUp, setOpenEHTopUp] = useState(false);
    const [eHAmount, setEHAmount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchEmploymentHeroFloat(), fetchEmploymentHeroTopUp()]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const fetchEmploymentHeroFloat = async () => {
        try {
            const headers = await matchHeaders(userRole);
            const response = await axios.get(api.retrieveEmploymentHeroFloat, {
                headers: {
                    'Content-Type': 'application/json',
                    apikey: headers.apikey,
                    tenant: headers.appTenant,
                    tenantA: headers.authTenant,
                },
            });

            // Normalize the summary object, ensuring all fields exist and default to 0 if missing
            const normalisedSummary = {
                totalPayouts: response.data.summary.totalPayouts ?? 0,
                numberOfPayouts: response.data.summary.numberOfPayouts ?? 0,
                numberOfDays: response.data.summary.numberOfDays ?? 0,
                pendingPayouts: response.data.summary.pendingPayouts ?? 0,
                availableBalance: response.data.summary.availableBalance ?? 0,
                averageAmountPerDay: response.data.summary.averageAmountPerDay ?? 0,
                daysLeft: response.data.summary.daysLeft ?? 0,
                numberOfPendingPayouts: response.data.summary.numberOfPendingPayouts ?? 0,
                failedPayouts: response.data.summary.failedPayouts ?? 0, // Not in response, default to 0
                numberOfFailedPayouts: response.data.summary.numberOfFailedPayouts ?? 0 // Not in response, default to 0
            };

            setEmploymentHeroFloatSummary(normalisedSummary);
        } catch (error) {
            console.error('Error fetching Employment Hero float:', error);
        } finally {
            setFloatALoading(false);
        }
    };

    const fetchEmploymentHeroTopUp = async () => {
        try {
            const headers = await matchHeaders(userRole);
            const response = await axios.get(api.retrieveEmploymentHeroTopUp, {
                headers: {
                    'Content-Type': 'application/json',
                    apikey: headers.apikey,
                    tenant: headers.appTenant,
                    tenantA: headers.authTenant,
                },
            });
            setEmploymentHeroTopUpValue(response.data.suggestedTopUpValue);
        } catch (error) {
            console.error('Error fetching Employment Hero top-up value:', error);
        } finally {
            setFloatATopUpLoading(false);
        }
    };

    const handleOpenEHTopUp = () => setOpenEHTopUp(true);
    const handleCloseEHTopUp = () => setOpenEHTopUp(false);

    const handleEHAmountChange = (event) => {
        setEHAmount(event.target.value); // Update the state with the input value
    };

    const handleEmploymentHeroTopUp = async () => {
        try {
            const headers = await matchHeaders(userRole);
            const response = await axios.post(
                api.topUpFloatPokitPal,
                { value: eHAmount, float: 'EMPLOYMENTHERO' },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        apikey: headers.apikey,
                        tenant: headers.appTenant,
                        tenantA: headers.authTenant,
                    }
                }
            );
            console.log('Top-up response:', response.data);
            handleCloseEHTopUp(); // Close modal on success
        } catch (error) {
            console.error('Error topping up Employment Hero float:', error);
        } finally {
            alert('TOP UP COMPLETE');
        }
    };

    return (
        <Container maxWidth="xxl">
            <ThemeProvider theme={mainTheme}>
                <CssBaseline />
                <MainNav userRole={userRole} />
                <Box
                    sx={{
                        padding: '20px',
                        marginLeft: isMobile ? '0' : 'auto',
                        marginRight: isMobile ? '0' : 'auto',
                        marginTop: '30px',
                        transition: 'margin-left 0.3s',
                    }}
                >
                    <Typography variant="h6" sx={{ marginBottom: '20px' }}>Employment Hero</Typography>

                    {floatALoading ? (
                        <TableContainer component={Paper} sx={{ width: '500px' }}>
                            <Table aria-label="EmploymentHero Float Summary">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#3ebeed' }}>
                                        <TableCell sx={{ fontSize: 14, fontWeight: 'bold', color: '#fff' }}>Details</TableCell>
                                        <TableCell align="right" sx={{ fontSize: 14, fontWeight: 'bold', color: '#fff' }}>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.from(new Array(10)).map((_, index) => (
                                        <TableRow key={index}>
                                            <TableCell><Typography variant="body2">{/* Placeholder */}</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="body2"><CircularProgress size={20} /></Typography></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <TableContainer component={Paper} sx={{ width: '500px' }}>
                            <Table aria-label="EmploymentHero Float Summary">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#3ebeed' }}>
                                        <TableCell sx={{ fontSize: 14, fontWeight: 'bold', color: '#fff' }}>Details</TableCell>
                                        <TableCell align="right" sx={{ fontSize: 14, fontWeight: 'bold', color: '#fff' }}>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Total Payouts</TableCell>
                                        <TableCell align="right">{formatCurrency(employmentHeroFloatSummary.totalPayouts)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Number of Payouts</TableCell>
                                        <TableCell align="right">{employmentHeroFloatSummary.numberOfPayouts}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Average Amount Per Day</TableCell>
                                        <TableCell align="right">{formatCurrency(employmentHeroFloatSummary.averageAmountPerDay)}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: '#e8f9ff' }}>
                                        <TableCell>Available Balance</TableCell>
                                        <TableCell align="right">{formatCurrency(employmentHeroFloatSummary.availableBalance)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Pending Payouts</TableCell>
                                        <TableCell align="right">{employmentHeroFloatSummary.numberOfPendingPayouts}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Pending Amount</TableCell>
                                        <TableCell align="right">{formatCurrency(employmentHeroFloatSummary.pendingPayouts)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Failed Payouts</TableCell>
                                        <TableCell align="right">{employmentHeroFloatSummary.numberOfFailedPayouts}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Failed Amount</TableCell>
                                        <TableCell align="right">{formatCurrency(employmentHeroFloatSummary.failedPayouts)}</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ backgroundColor: '#f2e9e9' }}>
                                        <TableCell>Days Left</TableCell>
                                        <TableCell align="right">{employmentHeroFloatSummary.daysLeft.toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Suggested Top Up Value</TableCell>
                                        <TableCell align="right">{floatATopUpLoading ? <CircularProgress size={20} /> : formatCurrency(employmentHeroTopUpValue)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    {(employmentHeroTopUpValue !== 0 || employmentHeroFloatSummary.daysLeft < 12) && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', marginTop: '40px' }}>
                            <Button variant="contained" onClick={handleOpenEHTopUp} sx={{ backgroundColor: theme.palette.action.add }}>Top Up Now</Button>
                        </Box>
                    )}

                    <Modal
                        open={openEHTopUp}
                        onClose={handleCloseEHTopUp}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 2,
                            }}
                        >
                            <Typography variant="h5" sx={{ marginBottom: 2 }}>Top Up Value</Typography>
                            <Typography variant="body2" sx={{ marginBottom: 2 }}>
                                Suggested top up value for 14 days is {formatCurrency(employmentHeroTopUpValue)}
                            </Typography>
                            <TextField
                                fullWidth
                                id="ehAmount"
                                name="ehAmount"
                                value={eHAmount}
                                label="Amount"
                                onChange={handleEHAmountChange}
                                required
                            />
                            <Typography variant="caption" sx={{ marginBottom: 2 }}>*$1 is 1, $50 is 50</Typography>
                            <Button fullWidth variant="contained" sx={{ marginTop: 2 }} onClick={handleEmploymentHeroTopUp}>
                                Top Up
                            </Button>
                        </Box>
                    </Modal>
                </Box>
            </ThemeProvider>
        </Container>
    );
};

export default EmploymentHeroFloatTable;