import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Grid2, Card, CardContent, Box, Container, Typography, useMediaQuery, ThemeProvider, CssBaseline, Badge, Skeleton } from '@mui/material';
import MainNav from '../components/navigation/MainNav';
import { api } from '../services/apis';
import { mainTheme, formatCurrency, matchHeaders } from '../services/settings';
import * as XLSX from 'xlsx';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GroupIcon from '@mui/icons-material/Group';
import { FaUsers } from 'react-icons/fa';
import NotificationsIcon from '@mui/icons-material/Notifications';

const DashboardNew = () => {
    const [isDashboardLoading, setIsDashboardLoading] = useState(false);
    const userRole = localStorage.getItem('role');
    const theme = mainTheme;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [users, setUsers] = useState([]);
    const [counts, setCounts] = useState({
        totalUsers: 0,
        consentedUsers: 0,
        onboardedUsers: 0,
        pausedUsers: 0,
        activeUsers: 0
    });
    const [roundUpsIsLoading, setRoundUpsIsLoading] = useState(true);
    const [usersIsLoading, setUsersIsLoading] = useState(true);
    const [loadingClubs, setLoadingClubs] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [chartDataTransactions, setChartDataTransactions] = useState([]);
    const [clubCount, setClubCount] = useState('');
    const [totalRoundUps, setTotalRoundUps] = useState('');
    const [inMonthRoundUps, setInMonthRoundUps] = useState('');
    const [lastMonthRoundUps, setLastMonthRoundUps] = useState('');
    const [NRMAPayoutAlertsData, setNRMAPayoutAlertsData] = useState({});
    const [pokitPalPayoutAlertsData, setPokitPalPayoutAlertsData] = useState({});
    const [alertCount, setAlertCount] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const loadDashboard = async () => {
            setIsDashboardLoading(true);
            try {
                const promises = [fetchTotalRoundUps(), fetchUsers()];
                if (userRole === 'ADMIN') {
                    promises.push(fetchClubList(), fetchPayoutAlerts());
                }
                if (userRole === 'ROUNDA') {
                    promises.push(fetchClubList());
                }
                await Promise.all(promises);
            } catch (error) {
                console.error("Error loading dashboard:", error);
            } finally {
                setIsDashboardLoading(false);
            }
        };

        loadDashboard();
    }, []);

    const fetchPayoutAlerts = async () => {
        try {
            const headers = await matchHeaders(userRole);
            const response = await axios.get('https://minerva.sipora.io/api/admin/payoutsAlert', {
                headers: {
                    'Content-Type': 'application/json',
                    apikey: headers.apikey,
                    tenant: headers.appTenant,
                    tenantA: headers.authTenant,
                }
            });

            const pokitPalPayouts = response.data.pokitPalPayouts.documents.length > 0 ? response.data.pokitPalPayouts.documents[0] : null;
            const nrmaPayouts = response.data.nrmaPayouts.documents.length > 0 ? response.data.nrmaPayouts.documents[0] : null;
            const payoutAlertCount = response.data.pokitPalPayouts.documents.length + response.data.nrmaPayouts.documents.length;
            setAlertCount(payoutAlertCount);

            if (nrmaPayouts) setNRMAPayoutAlertsData(nrmaPayouts);
            if (pokitPalPayouts) setPokitPalPayoutAlertsData(pokitPalPayouts);
        } catch (error) {
            console.error('Error fetching payoutAlerts:', error);
        }
    };

    const fetchClubList = async () => {
        try {
            const headers = await matchHeaders(userRole);
            const response = await axios.get(api.listClubNames, {
                headers: {
                    'Content-Type': 'application/json',
                    apikey: headers.apikey,
                    tenant: headers.appTenant,
                    tenantA: headers.authTenant,
                }
            });

            setClubCount(response.data.clubs.length);
        } catch (error) {
            console.error('Error fetching club list:', error);
        } finally {
            setLoadingClubs(false);
        }
    };

    const fetchUsers = async () => {
        try {
            const headers = await matchHeaders(userRole);
            let allUsers = [];
            let page = 1;
            let totalPages = 1;

            // Fetch all pages
            do {
                const response = await axios.get(api.retrieveUsers, {
                    params: {
                        page,
                        limit: 1000, // Use the API's limit
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        apikey: headers.apikey,
                        tenant: headers.appTenant,
                        tenantA: headers.authTenant,
                    }
                });

                allUsers = allUsers.concat(response.data.users);
                totalPages = response.data.totalPages;
                page++;
            } while (page <= totalPages);

            setUsers(allUsers);

            // Calculate counts from all users
            const consentedUsersCount = allUsers.filter(user => user.isConsent === true).length;
            const onboardedUsersCount = allUsers.filter(user => user.onboardingComplete === true).length;
            const pausedUsersCount = allUsers.filter(user => user.isUserPausedRoundUp === true).length;
            setCounts({
                totalUsers: allUsers.length,
                consentedUsers: consentedUsersCount,
                onboardedUsers: onboardedUsersCount,
                pausedUsers: pausedUsersCount,
                activeUsers: onboardedUsersCount - pausedUsersCount
            });

            setChartData(aggregateUsersByMonth(allUsers));
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setUsersIsLoading(false);
        }
    };

    const fetchTotalRoundUps = async () => {
        try {
            const headers = await matchHeaders(userRole);
            const response = await axios.get(api.totalRoundups, {
                headers: {
                    'Content-Type': 'application/json',
                    apikey: headers.apikey,
                    tenant: headers.appTenant,
                    tenantA: headers.authTenant,
                }
            });

            const transactionForChart = transformData(response.data.documents);
            setChartDataTransactions(transactionForChart);

            const totalAmount = response.data.documents.reduce((acc, curr) => acc + curr.totalDebitAmount, 0) / 100;

            const getCurrentMonth = () => {
                const date = new Date();
                return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;
            };

            const getPreviousMonth = () => {
                const date = new Date();
                return `${date.getFullYear()}-${date.getMonth().toString().padStart(2, '0')}`;
            };

            const currentMonth = getCurrentMonth();
            const previousMonth = getPreviousMonth();

            const monthAmount = response.data.documents
                .filter(doc => doc.month === currentMonth)
                .reduce((acc, curr) => acc + curr.totalDebitAmount, 0) / 100;

            const lastMonthAmount = response.data.documents
                .filter(doc => doc.month === previousMonth)
                .reduce((acc, curr) => acc + curr.totalDebitAmount, 0) / 100;

            setTotalRoundUps(totalAmount);
            setInMonthRoundUps(monthAmount);
            setLastMonthRoundUps(lastMonthAmount);
        } catch (error) {
            console.error('Error fetching debits:', error);
        } finally {
            setRoundUpsIsLoading(false);
        }
    };

    const transformData = (data) => {
        const formattedData = [];
        data.forEach((item) => {
            let monthEntry = formattedData.find(entry => entry.month === item.month);
            if (!monthEntry) {
                monthEntry = { month: item.month };
                formattedData.push(monthEntry);
            }
            monthEntry[item.tenantName] = item.totalDebitAmount / 100;
        });
        return formattedData;
    };

    const aggregateUsersByMonth = (users) => {
        const usersByMonth = {};
        users.forEach((user) => {
            if (user._createdDate && user.tenant) {
                const yearMonth = format(new Date(user._createdDate), 'yyyy-MM');
                if (!usersByMonth[yearMonth]) usersByMonth[yearMonth] = {};
                if (!usersByMonth[yearMonth][user.tenant]) usersByMonth[yearMonth][user.tenant] = 0;
                usersByMonth[yearMonth][user.tenant] += 1;
                if (!usersByMonth[yearMonth]['All Tenants']) usersByMonth[yearMonth]['All Tenants'] = 0;
                usersByMonth[yearMonth]['All Tenants'] += 1;
            }
        });

        const sortedMonths = Object.keys(usersByMonth).sort();
        const cumulativeData = [];
        let cumulativeCounts = {};

        sortedMonths.forEach((month) => {
            let currentMonthData = { month };
            for (const tenant in usersByMonth[month]) {
                if (!cumulativeCounts[tenant]) cumulativeCounts[tenant] = 0;
                cumulativeCounts[tenant] += usersByMonth[month][tenant];
                currentMonthData[tenant] = cumulativeCounts[tenant];
            }
            cumulativeData.push(currentMonthData);
        });

        return cumulativeData;
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(users);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'UsersData.xlsx');
    };

    const dashboardCardsCounts = [
        { title: 'Total Users', value: counts.totalUsers, icon: <FaUsers size={30} />, color: '#058efc', isLoading: usersIsLoading },
        { title: 'Consented Users', value: counts.consentedUsers, icon: <CheckCircleIcon fontSize="large" />, color: '#733aea', isLoading: usersIsLoading },
        { title: 'Onboard Complete', value: counts.onboardedUsers, icon: <PeopleIcon fontSize="large" />, color: '#fd9722', isLoading: usersIsLoading },
        { title: 'Active Users', value: counts.activeUsers, icon: <PeopleIcon fontSize="large" />, color: '#f2426e', isLoading: usersIsLoading },
        { title: 'Paused Users', value: counts.pausedUsers, icon: <PeopleIcon fontSize="large" />, color: '#058efc', isLoading: usersIsLoading },
        ...(userRole === 'ROUNDA' || userRole === 'ADMIN' ? [{ title: 'Clubs', value: clubCount, icon: <GroupIcon fontSize="large" />, color: '#f2426e', isLoading: loadingClubs }] : []),
    ];

    const dashboardCardsValues = [
        { title: 'This Month RoundUps', value: formatCurrency(inMonthRoundUps), color: '#058efc', isLoading: roundUpsIsLoading },
        { title: 'Last Month RoundUps', value: formatCurrency(lastMonthRoundUps), color: '#733aea', isLoading: roundUpsIsLoading },
        { title: 'Total RoundUps', value: formatCurrency(totalRoundUps), color: '#fd9722', isLoading: roundUpsIsLoading }
    ];

    return (
        <Container maxWidth="xxl">
            <ThemeProvider theme={mainTheme}>
                <CssBaseline />
                <MainNav userRole={userRole} />
                <Box sx={{ padding: '20px', marginLeft: isMobile ? '0' : '300px', marginTop: '50px', transition: 'margin-left 0.3s' }}>
                    {isDashboardLoading ? (
                        <>
                            <Grid2 container justifyContent="space-between">
                                <Skeleton variant="text" width="200px" height="40px" />
                                <Skeleton variant="circular" width={30} height={30} />
                            </Grid2>
                            <Grid2 container spacing={3} mb={6}>
                                {Array.from(new Array(4)).map((_, index) => (
                                    <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Card sx={{ width: 250, height: 100, borderRadius: '10px', borderColor: '#e3e3e3' }}>
                                            <CardContent>
                                                <Skeleton variant="rectangular" width={40} height={40} />
                                                <Box mt={2}>
                                                    <Skeleton variant="text" width="60%" height={28} />
                                                    <Skeleton variant="text" width="40%" height={20} />
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid2>
                                ))}
                            </Grid2>
                            <Grid2 container spacing={3} mb={6}>
                                {Array.from(new Array(4)).map((_, index) => (
                                    <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Card sx={{ width: 250, height: 100, borderRadius: '10px', borderColor: '#e3e3e3' }}>
                                            <CardContent>
                                                <Skeleton variant="rectangular" width={40} height={40} />
                                                <Box mt={2}>
                                                    <Skeleton variant="text" width="60%" height={28} />
                                                    <Skeleton variant="text" width="40%" height={20} />
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid2>
                                ))}
                            </Grid2>
                            <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px' }}>
                                <Skeleton variant="text" width="30%" height={40} />
                                <Skeleton variant="rectangular" width="100%" height={400} />
                            </Box>
                            {userRole === 'ADMIN' && (
                                <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px' }}>
                                    <Skeleton variant="text" width="30%" height={40} />
                                    <Skeleton variant="rectangular" width="100%" height={400} />
                                </Box>
                            )}
                        </>
                    ) : (
                        <>
                            <Grid2 container justifyContent="space-between">
                                <Typography variant="h4" gutterBottom>Dashboard</Typography>
                                {alertCount ? (
                                    <Badge badgeContent={alertCount} color="error">
                                        <NotificationsIcon sx={{ fontSize: 30, cursor: 'pointer' }} />
                                    </Badge>
                                ) : null}
                            </Grid2>

                            <Grid2 container spacing={3} mb={6}>
                                {dashboardCardsCounts.map((card, index) => (
                                    <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Card sx={{ width: 250, height: 100, borderRadius: '10px', borderColor: '#e3e3e3', color: '#000' }}>
                                            <CardContent sx={{ textAlign: 'left' }}>
                                                <Box>
                                                    <Grid2 container>
                                                        <Box padding={2}>{card.icon}</Box>
                                                        <Grid2 item>
                                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{card.value}</Typography>
                                                            <Typography variant="subtitle1" sx={{ color: 'grey' }}>{card.title}</Typography>
                                                        </Grid2>
                                                    </Grid2>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid2>
                                ))}
                            </Grid2>

                            <Grid2 container spacing={3} mb={6}>
                                {dashboardCardsValues.map((card, index) => (
                                    <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Card sx={{ width: 250, height: 100, borderRadius: '10px', borderColor: '#e3e3e3', color: '#000' }}>
                                            <CardContent sx={{ textAlign: 'left' }}>
                                                <Box marginLeft={2}>
                                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{card.value}</Typography>
                                                    <Typography variant="subtitle1" sx={{ color: 'grey' }}>{card.title}</Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid2>
                                ))}
                            </Grid2>

                            {userRole === 'ADMIN' && (
                                <>
                                    <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px', boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)' }}>
                                        <Typography variant="h6">User Growth month on month - All Tenants</Typography>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <LineChart data={chartData}>
                                                <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                                                <XAxis dataKey="month" stroke="#888" />
                                                <YAxis stroke="#888" />
                                                <Tooltip />
                                                <Legend align="right" verticalAlign="top" iconType="circle" />
                                                <Line type="monotone" dataKey="All Tenants" stroke="#ff7300" strokeWidth={2} activeDot={{ r: 8 }} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Box>

                                    <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px', boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)' }}>
                                        <Typography variant="h6">User Growth month on month - By Tenant</Typography>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <LineChart data={chartData}>
                                                <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                                                <XAxis dataKey="month" stroke="#888" />
                                                <YAxis stroke="#888" />
                                                <Tooltip />
                                                <Legend align="right" verticalAlign="top" iconType="circle" />
                                                <Line type="monotone" dataKey="CENTS2BILLS-PROD" stroke="#8884d8" strokeWidth={2} />
                                                <Line type="monotone" dataKey="ROUNDA-STAGE" stroke="#82ca9d" strokeWidth={2} />
                                                <Line type="monotone" dataKey="POKITPAL-PROD" stroke="#ffc658" strokeWidth={2} />
                                                <Line type="monotone" dataKey="BOOST-YOUR-SUPER-PROD" stroke="#ff7300" strokeWidth={2} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Box>

                                    <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px', boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)' }}>
                                        <Typography variant="h6">Monthly Direct Debits by Tenant</Typography>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <LineChart data={chartDataTransactions}>
                                                <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                                                <XAxis dataKey="month" stroke="#888" />
                                                <YAxis stroke="#888" />
                                                <Tooltip />
                                                <Legend align="right" verticalAlign="top" iconType="circle" />
                                                <Line type="monotone" dataKey="CENTS2BILLS-PROD" stroke="#8884d8" strokeWidth={2} />
                                                <Line type="monotone" dataKey="ROUNDA-STAGE" stroke="#82ca9d" strokeWidth={2} />
                                                <Line type="monotone" dataKey="POKITPAL-PROD" stroke="#ffc658" strokeWidth={2} />
                                                <Line type="monotone" dataKey="BOOST-YOUR-SUPER-PROD" stroke="#ff7300" strokeWidth={2} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </Box>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </ThemeProvider>
        </Container>
    );
};

export default DashboardNew;